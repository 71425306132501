import React from "react";

const Users = React.lazy(() => import("./users"));
const Properties = React.lazy(() => import("./properties"));
export const administrationConfig = [
  {
    path: "/administration/users",
    element: <Users />,
  },
  {
    path: "/administration/properties",
    element: <Properties />,
  },
];
