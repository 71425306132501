import React, { ReactNode } from "react";
import { BiCollection, BiTrophy, BiUserCircle, BiBuildings } from "react-icons/bi";
import { RoutePermittedRole } from "../shared/constants/AppConst";

export interface RouterConfigData {
  id: string;
  title: string;
  messageId: string;
  icon?: string | ReactNode;
  type: "item" | "group" | "collapse" | "divider";
  children?: RouterConfigData[];
  permittedRole?: RoutePermittedRole;
  color?: string;
  url?: string;
  exact?: boolean;
  count?: number;
}
const routesConfig: RouterConfigData[] = [
  {
    id: "app",
    title: "Four Seasons",
    messageId: "Reports",
    type: "group",
    children: [
      {
        id: "summary",
        title: "Summary",
        messageId: "Summary",
        type: "item",
        icon: <BiCollection />,
        url: "/home/summary",
      },
      {
        id: "progress",
        title: "Progress",
        messageId: "Progress",
        type: "item",
        icon: <BiTrophy />,
        url: "/home/progress",
      },
    ],
  },
  {
    id: "app",
    title: "Four Seasons",
    messageId: "Account Management",
    type: "group",
    children: [
      {
        id: "User Management",
        title: "User Management",
        messageId: "User Management",
        type: "item",
        icon: <BiUserCircle />,
        url: "/administration/users",
      },
      {
        id: "Properties",
        title: "Properties",
        messageId: "Properties",
        type: "item",
        icon: <BiBuildings />,
        url: "/administration/properties",
      },
    ],
  },
];
export default routesConfig;
