import React from "react";

const Summary = React.lazy(() => import("./summary"));
const Progress = React.lazy(() => import("./progress"));
export const homeConfig = [
  {
    path: "/home/summary",
    element: <Summary />,
  },
  {
    path: "/home/progress",
    element: <Progress />,
  },
];
