import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// Initialize Firebase

const firebaseConfig = {
  apiKey: "AIzaSyAyjDtKfIJnKOnTTwG7EBmo3x-3BOMK9tk",
  authDomain: "app-targetier.firebaseapp.com",
  databaseURL: "https://crema-react.firebaseio.com",
  projectId: "app-targetier",
  storageBucket: "app-targetier.appspot.com",
  messagingSenderId: "654802405630",
  appId: "1:654802405630:web:1daf6a6bdb09b0502f3daf",
  measurementId: "G-BLK9BF9WS2"
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

export {
  auth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
};
